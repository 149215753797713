import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';

import SlabInventoryPopUp from '../components/custom/slab-inventory-popup/SlabInventoryPopUp';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { FavoriteTypes } from '../components/shared/enum/favorite-data-type';
import IconsBar from '../components/shared/icons-bar/IconsBar';
import { GqlSlab } from '../components/shared/interfaces';
import ImageSliderWithThumbnail from '../components/shared/slider-with-thumbnail/image/ImageSliderWithThumbnail';
import DetailsDataCell from '../components/slab-product/details-data-cell/DetailsDataCell';
import PropositionCard from '../components/slab-product/proposition-card/PropositionCard';
import useFavourite from '../hooks/useFavourite';
import useViewport from '../hooks/useViewport';
import styles from './slab-template.module.scss';

interface Props {
  data: SlabData;
  pageContext: {
    similarSlabs: GqlSlab[];
  };
}

export interface SlabData {
  strapi: {
    slab: GqlSlab;
  };
}

interface ImagesArray {
  image: {
    url: string;
    caption?: string;
    alternativeText?: string;
  };
}

export const query = graphql`
  query($id: ID!) {
    strapi {
      slab(id: $id) {
        id
        sku
        color_primary {
          id
          name
        }
        color_secondary {
          id
          name
        }
        color_tertiary {
          id
          name
        }
        material {
          id
          name
        }
        finish {
          id
          name
        }
        images {
          url
          alternativeText
        }
        thickness
        description
        name
        size
        preview {
          url
        }
      }
    }
  }
`;

const SlabTemplate: React.FC<Props> = ({ data, pageContext: { similarSlabs } }: Props) => {
  const { strapi } = data;
  const { slab } = strapi;

  const { isMobile } = useViewport();
  const favoriteAPI = useFavourite();
  const [isFavoriteSaved, setIsFavoriteSaved] = React.useState(false);

  const setSavedFavorites = (isFavoriteSaved: boolean) => {
    setIsFavoriteSaved(isFavoriteSaved);
  };
  const [imageId, setImageId] = useState(0);

  const [isSearched, setIsSearched] = React.useState(false);

  const toggleSearch = () => {
    setIsSearched(!isSearched);
  };

  const slabImagesArray: ImagesArray[] = [];

  for (const key of slab.images) {
    slabImagesArray.push({ image: key });
  }

  slabImagesArray.sort((a?, b?) => {
    if (a.image.alternativeText && b.image.alternativeText) {
      let numberA = '';
      let numberB = '';
      for (let i = 0; i < [...a.image.alternativeText].length; i++) {
        const it = a.image.alternativeText[i];
        if (/[0-9]/.test(it)) {
          numberA += it;
        } else {
          break;
        }
      }
      for (let i = 0; i < [...b.image.alternativeText].length; i++) {
        const it = b.image.alternativeText[i];
        if (/[0-9]/.test(it)) {
          numberB += it;
        } else {
          break;
        }
      }
      return Number(numberA) - Number(numberB);
    }
  });

  useEffect(() => {
    const data = `${window.location.href}#${
      slab.name.split(' ').join('_') + '_' + slab.size?.split(' ').join('')
    }`;
    window.history.replaceState({}, '', data.split('#').slice(0, 2).join('#'));
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Layout isWhiteBackground={true}>
      <SEO title={slab.name} />
      <section className={styles.wrapper}>
        <div className={styles.detailsBox}>
          <div className={styles.sliderBox}>
            <nav className={styles.iconsBar}>
              <IconsBar
                imageUrl={slab.images[imageId].url}
                isFavoriteSaved={isFavoriteSaved}
                slab
                toggleSearch={toggleSearch}
                isSearched={isSearched}
              >
                <SlabInventoryPopUp
                  favouriteAPI={favoriteAPI}
                  title={slab.name}
                  materialName={slab.material?.name}
                  finishName={slab.finish?.name}
                  imagesPreview={slab?.preview?.url}
                  collectionId={slab.id}
                  collectionType={FavoriteTypes.SLAB}
                  setSavedFavorites={setSavedFavorites}
                />
              </IconsBar>
            </nav>
            <section className={styles.slider}>
              <h1 className={styles.title}>LIVE SLAB INVENTORY | {slab.name.toUpperCase()}</h1>
              <ImageSliderWithThumbnail
                isSearched={isSearched}
                slideHeight={isMobile ? '250px' : '425px'}
                images={slabImagesArray}
                isHidePhotographerCredit={true}
                setFinishId={setImageId}
                isScaleImg={true}
              />
            </section>
          </div>
          <div className={styles.descriptionBox}>
            <section className={styles.detailsDivider}>
              <div className={styles.delimiter}></div>
              <div className={styles.content}>
                <span className={styles.dividerHeader}>SLAB</span>
                <span className={styles.dividerText}>SCROLL TO SEE MORE</span>
              </div>
            </section>
            <section className={styles.details}>
              <h1 className={styles.detailsHeader}>{slab.name}</h1>
              <DetailsDataCell title="MATERIAL" value={slab.material?.name} />
              <DetailsDataCell title="SIZE" value={slab.size ?? ''} />
              <DetailsDataCell title="THICKNESS" value={slab.thickness ?? ''} />
              <DetailsDataCell title="FINISH" value={slab.finish?.name} />
              <DetailsDataCell
                title="COLOR"
                value={[slab.color_primary, slab.color_secondary, slab.color_tertiary]
                  .filter((c) => c)
                  .map((c) => c.name)
                  .join(', ')}
              />
              {slab.description && (
                <DetailsDataCell
                  title="SPECIAL CHARACTERISTICS"
                  value={slab.description}
                  isText={true}
                />
              )}
              {slab?.sku && <DetailsDataCell title="SKU" value={slab.sku} />}
            </section>
          </div>
        </div>
        <section className={styles.proposition}>
          <h1 className={styles.propositionHeader}>YOU MIGHT ALSO LOVE</h1>
          <div className={styles.propositionCards}>
            {similarSlabs[0] && <PropositionCard slab={similarSlabs[0]}></PropositionCard>}
            {similarSlabs[1] && <PropositionCard slab={similarSlabs[1]}></PropositionCard>}
            {similarSlabs[2] && <PropositionCard slab={similarSlabs[2]}></PropositionCard>}
            {similarSlabs[3] && <PropositionCard slab={similarSlabs[3]}></PropositionCard>}
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default SlabTemplate;
