import React from 'react';

import { Slab, GqlSlab } from '../../shared/interfaces';
import styles from './PropositionCard.module.scss';
import { Link } from 'gatsby';

interface Props {
  slab: GqlSlab;
}

const PropositionCard: React.FC<Props> = ({ slab }: Props) => (
  <Link to={`/slab/${slab.id}`}>
    <div className={styles.container}>
      <div className={styles.delimiter}></div>
      <button className={styles.imageButton}>
        <img className={styles.image} src={slab.preview?.url} alt="proposition image" />
      </button>
      <div className={styles.delimiter}></div>
    </div>
  </Link>
);

export default PropositionCard;
