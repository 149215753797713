import React from 'react';
import styles from './DetailsDataCell.module.scss';
import { Link } from 'gatsby';
import iconBlackDesktop from '../../../images/Desktop_Black.svg';
import iconWhiteDesktop from '../../../images/Desktop_White.svg';
import iconBlackMobile from '../../../images/Mobile_Black.svg';
import useViewport from '../../../hooks/useViewport';

interface Props {
  title: string;
  value: string;
  isText?: boolean;
  notes?: string;
  titleLeftSpan?: string;
  order?: number;
  width?: string;
}

const DetailsDataCell: React.FC<Props> = ({
  title,
  value,
  isText,
  notes,
  titleLeftSpan,
  order,
  width,
}: Props) => {
  const { isTablet } = useViewport();
  const blackIcon = isTablet ? iconBlackMobile : iconBlackDesktop;

  return (
    <section className={styles.container} style={{ order: order, width: width }}>
      <span className={styles.title}>
        {titleLeftSpan && <span>{titleLeftSpan}</span>}
        {title}
      </span>
      {title === 'SIZE' || title === 'SIZES AVAILABLE' ? (
        <span className={isText ? styles.text : styles.value}>
          {value &&
            value.split(',').map((item, i) => (
              <span key={i} className={isText ? styles.text : styles.value}>
                {item}
                <span
                  style={
                    i < value.split(',').length - 1
                      ? { margin: '0.4rem', opacity: '0.5' }
                      : { display: 'none' }
                  }
                >
                  {' | '}
                </span>
              </span>
            ))}
        </span>
      ) : (
        <span className={styles.text}>
          {value}
          {title === 'STANDARD FINISHES' && (
            <>
              <br />
              <Link to="/finishes-library" style={{ margin: '1rem 0 .5rem', display: 'block' }}>
                <span style={{ fontWeight: 700, fontSize: '1.45rem', letterSpacing: '.15rem' }}>
                  SEE ALL FINISHES
                </span>
                <img
                  alt="contact icon"
                  style={{
                    marginLeft: '3rem',
                    height: '1.5rem',
                    maxHeight: '1.5rem',
                    marginBottom: '-.2rem',
                  }}
                  src={blackIcon}
                />
              </Link>
            </>
          )}
        </span>
      )}
      {notes != undefined && title !== 'FINISH OPTIONS' && (
        <span className={styles.notes}>{notes}</span>
      )}
      <div className={styles.delimiter}></div>
    </section>
  );
};

export default DetailsDataCell;
